import {Fragment} from "react";
import Head from "next/head";
import {LiveChat} from "@/components/Shared/LiveChat";
import {Layout} from "@/components/Shared/Layout/Layout";
import {PageNotFound} from "@/components/Shared/PageNotFound";

export default function FourOhFour() {
    return (
        <Fragment>
            <Head>
                <title>Page Not Found | canibuild</title>
            </Head>
            <LiveChat />
            <Layout>
                <PageNotFound />
            </Layout>
        </Fragment>
    );
}